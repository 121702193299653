import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  UseDisclosureProps,
  VStack,
} from "@chakra-ui/react"
import * as React from "react"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"

import BlockHeader from "../components/blockHeader"
import Layout from "../components/layout"
import SEO from "../components/CoreUI/SEO"

interface TeamMember {
  portrait: {
    description: string
    gatsbyImageData: IGatsbyImageData
  }
  name: string
  order: number
  jobTitle: string
  yearStarted: number
  favoriteTool: string
  hometown: string
  hobbiesHome: {
    childMarkdownRemark: {
      html: string
    }
  }
  workExperience: {
    childMarkdownRemark: {
      html: string
    }
  }
  jobDuties: {
    childMarkdownRemark: {
      html: string
    }
  }
  favoriteParkOfWork: {
    childMarkdownRemark: {
      html: string
    }
  }
}

interface TeamProps extends PageProps {
  data: {
    teamMembers: {
      nodes: TeamMember[]
    }
  }
}

interface TeamMemberProps {
  member: TeamMember
  showDetails: (member: TeamMember) => void
}

interface TeamMemberModalProps extends UseDisclosureProps {
  member: TeamMember
}

function TeamMember({ member, showDetails }: TeamMemberProps): JSX.Element {
  return (
    <VStack h="full" w="full" spacing="18px">
      <Box>
        <Image
          alt={member.portrait.description}
          as={GatsbyImage}
          image={member.portrait.gatsbyImageData}
          objectFit="contain"
          rounded="xl"
          maxW="300px"
        />
      </Box>
      <VStack spacing="2px" alignItems="center">
        <Text
          className="decor"
          fontSize="2xl"
          fontWeight="extrabold"
          borderBottom="4px solid"
          borderColor="red.500"
          w="fit-content"
        >
          {member.name}
        </Text>
        <Text
          color="gray.500"
          fontSize="sm"
          fontWeight="bold"
          py={1}
          rounded="lg"
          textAlign="center"
          textTransform="uppercase"
        >
          {member.jobTitle}
        </Text>
        <Button
          color="red.500"
          size="sm"
          variant="link"
          onClick={() => showDetails(member)}
        >
          Meet {member.name.split(` `)[0]}
        </Button>
      </VStack>
    </VStack>
  )
}

function TeamMemberModal({
  isOpen,
  onClose,
  member,
}: TeamMemberModalProps): JSX.Element {
  return (
    <Modal allowPinchZoom isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.800" />
      <ModalContent
        maxW="95vw"
        w={[`95vw`, `80vw`, `600px`, `1000px`]}
        rounded="lg"
      >
        <Grid
          templateAreas={[`"img" "words"`, null, null, `"img words"`]}
          templateColumns={[`95vw`, `80vw`, `600px`, `35% 65%`]}
          templateRows={[`30vh 50vh`, null, null, `400px`]}
          py={8}
        >
          <GridItem
            bgGradient={[
              `linear(to-b, white 40%, red.500 40%, red.500 90%, white 90%)`,
              null,
              null,
              `none`,
            ]}
            gridArea="img"
          >
            <Image
              alt={member?.portrait.description}
              as={GatsbyImage}
              image={member?.portrait.gatsbyImageData}
              h="100%"
              imgStyle={{ height: `100%`, objectFit: `contain` }}
              mx="auto"
            />
          </GridItem>
          <GridItem gridArea="words" pt={4}>
            <Flex
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
              h="full"
              w="full"
              px={6}
              overflowY="scroll"
            >
              <Text
                className="decor"
                fontSize="3xl"
                fontWeight="extrabold"
                pt={[2, null, null, 12]}
              >
                {member.name}
              </Text>
              {member.jobTitle && (
                <Text
                  color="red.500"
                  fontSize="sm"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {member.jobTitle}
                </Text>
              )}
              {member.yearStarted && (
                <Text fontStyle="italic" fontSize="sm">
                  Joined Sundog in {member.yearStarted}
                </Text>
              )}
              {member.favoriteTool && (
                <VStack alignItems="start" pt={4} spacing="0px" w="100%">
                  <Text
                    color="gray.800"
                    fontSize="sm"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Favorite Power Tool
                  </Text>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: member.favoriteTool,
                    }}
                  />
                </VStack>
              )}
              {member.hobbiesHome && (
                <VStack alignItems="start" pt={4} spacing="0px" w="100%">
                  <Text
                    color="gray.800"
                    fontSize="sm"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Family
                  </Text>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: member.hobbiesHome.childMarkdownRemark.html,
                    }}
                  />
                </VStack>
              )}
              {member.workExperience && (
                <VStack alignItems="start" pt={4} spacing="0px" w="100%">
                  <Text
                    color="gray.800"
                    fontSize="sm"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Work Experience
                  </Text>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: member.workExperience.childMarkdownRemark.html,
                    }}
                  />
                </VStack>
              )}
              {member.jobDuties && (
                <VStack alignItems="start" pt={4} spacing="0px" w="100%">
                  <Text
                    color="gray.800"
                    fontSize="sm"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Duties
                  </Text>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: member.jobDuties.childMarkdownRemark.html,
                    }}
                  />
                </VStack>
              )}
              {member.favoriteParkOfWork && (
                <VStack alignItems="start" pt={4} spacing="0px" w="100%">
                  <Text
                    color="gray.800"
                    fontSize="sm"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Favorite Part of Work
                  </Text>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html:
                        member.favoriteParkOfWork.childMarkdownRemark.html,
                    }}
                  />
                </VStack>
              )}
            </Flex>
          </GridItem>
        </Grid>
        <ModalCloseButton size="lg" />
      </ModalContent>
    </Modal>
  )
}

function Team({ data, location }: TeamProps): JSX.Element {
  const { teamMembers } = data

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [member, setMember] = React.useState<TeamMember>(teamMembers.nodes[0])

  const showDetails = (member: TeamMember) => {
    setMember(member)
    onOpen()
  }

  return (
    <Layout>
      <SEO
        meta={{
          description: "Meet the talented team behind Sundog Homes.",
          image: `${location.origin}/sundog-team-2023.png`,
          imageAlt: `The Sundog Team`,
        }}
        title="Team | Sundog Homes"
        url={location.href}
      />
      <Box maxW="1200px" mt={[8, null, 4]} mx="auto" w="100vw">
        <StaticImage
          alt="The Sundog Homes team."
          className="squared"
          src="../images/sundog-team-2023.png"
          layout="fullWidth"
          style={{ height: `100%`, width: `100%` }}
        />
      </Box>
      <Flex
        align="center"
        flexDir="column"
        justify="center"
        maxW="95vw"
        mb={24}
        mt={12}
        mx="auto"
        w="800px"
      >
        <BlockHeader>Our Team</BlockHeader>
        <Text
          className="decor"
          fontSize="5xl"
          fontWeight="extrabold"
          lineHeight="shorter"
          mt={4}
          textAlign="center"
        >
          Meet your new support staff.
        </Text>
      </Flex>
      <Box w="100vw">
        <SimpleGrid
          columns={[1, 2, null, 3]}
          maxW="95vw"
          mb={24}
          mx="auto"
          spacing="40px"
          w="1200px"
        >
          {teamMembers.nodes.map(teamMember => (
            <TeamMember member={teamMember} showDetails={showDetails} />
          ))}
        </SimpleGrid>
      </Box>
      <TeamMemberModal isOpen={isOpen} onClose={onClose} member={member} />
    </Layout>
  )
}

export default Team

export const query = graphql`
  query {
    teamMembers: allContentfulTeamMember(
      filter: { organization: { eq: "Sundog Homes" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        ...TeamMember
      }
    }
  }
`
